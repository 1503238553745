import { useEffect } from 'react';

// Context
import { useContext } from 'Lib/Contexts/Loading';

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useContext((props) => {
    useEffect(() => {
        props.loading.resourceLoading(true);

        return () => {
            props.loading.resourceLoading(false);
        };
    });

    return null;
});
