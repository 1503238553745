var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
// Contexts
import * as HttpContext from 'Lib/Contexts/Http';
import * as TokenContext from 'Lib/Contexts/Token';
var initState = {
    id: null,
    credit: { primary: { balance: 0, status: 0 }, promo: { balance: 0, status: 0 } },
    online: {
        lastOnlineTime: '',
        socketStatus: 0,
        delayStatus: 0,
        manualStatus: null,
        status: 0,
    },
    phoneNumber: '',
    phoneNumberVerified: true,
    isVerified: false,
    isEnable: true,
    isInsuranceEnable: false,
    isEnableTelemedicine: false,
    profile: {
        email: '',
        about: '',
        title: '',
        gender: '',
        fullName: '',
        certified: '',
        graduateYear: 0,
        workingRegion: '',
        birthDate_date: 0,
        birthDate_year: 0,
        birthDate_month: 0,
        medicalSpecialties: [],
        rating: { count: 0, average: 0 },
        stats: { questions: { answerCount: 0, thanksCount: 0, rateCount: 0, rateSum: 0 } },
    },
    configurations: {
        notifications: { question: true, consultation: true },
        theme: null,
        schedule: [],
    },
    referenceCode: '',
    registerTime: '',
    consultationOptions: {
        callcenter: { isEnable: false, price: 0 },
        text: { isEnable: true, duration: 0, price: 0 },
        voice: { isEnable: false, duration: 0, price: 0 },
        video: { isEnable: false, duration: 0, price: 0 },
    },
    createTime: '',
    isSetPassword: false,
    status: '',
};
var initMethodState = {
    refresh: function () { return null; },
    offSetPassword: function () { return null; },
    setUpdateAvatar: function () { return null; },
    setDoctorAvatar: function () { return null; },
    doctorAvatar: '',
    updateAvatar: '',
};
export var Context = React.createContext(initState);
export var MethodContext = React.createContext(initMethodState);
export var useAccountContext = function () {
    var account = React.useContext(Context);
    var _a = React.useContext(MethodContext), refresh = _a.refresh, offSetPassword = _a.offSetPassword, setUpdateAvatar = _a.setUpdateAvatar, doctorAvatar = _a.doctorAvatar, setDoctorAvatar = _a.setDoctorAvatar, updateAvatar = _a.updateAvatar;
    return __assign(__assign({}, account), { refresh: refresh,
        offSetPassword: offSetPassword,
        setUpdateAvatar: setUpdateAvatar,
        doctorAvatar: doctorAvatar,
        updateAvatar: updateAvatar,
        setDoctorAvatar: setDoctorAvatar });
};
var Account = function (props) {
    var history = useHistory();
    var _a = useState(initState), account = _a[0], setAccount = _a[1];
    var _b = useState(false), updateAvatar = _b[0], setUpdateAvatar = _b[1];
    var _c = useState(''), doctorAvatar = _c[0], setDoctorAvatar = _c[1];
    var httpContext = HttpContext.useHttpContext();
    var tokenContext = TokenContext.useTokenContext();
    var getAccountSummary = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, doctor;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpContext.get('/v1/account/summary')];
                case 1:
                    response = _a.sent();
                    doctor = response.doctor || {};
                    setAccount(doctor);
                    return [2 /*return*/];
            }
        });
    }); };
    var setOffSetPassword = function () { return setAccount(__assign(__assign({}, account), { isSetPassword: true })); };
    useEffect(function () {
        if (tokenContext.value && !account.id) {
            getAccountSummary();
        }
        else if (!account.isVerified) {
            history.push({
                pathname: '/cho-xac-thuc',
                state: { phoneNumber: account.phoneNumber },
            });
            if (!localStorage.getItem('register_doctor_records')) {
                localStorage.setItem('register_doctor_records', '1');
            }
        }
    }, [account.id, tokenContext.value, account.isVerified, account.phoneNumber]);
    var accountMethodValue = {
        setUpdateAvatar: setUpdateAvatar,
        updateAvatar: updateAvatar,
        doctorAvatar: doctorAvatar,
        setDoctorAvatar: setDoctorAvatar,
        refresh: getAccountSummary,
        offSetPassword: setOffSetPassword,
    };
    return useMemo(function () {
        return (React.createElement(Context.Provider, { value: account },
            React.createElement(MethodContext.Provider, { value: accountMethodValue }, props.children)));
    }, [account]);
};
export default Account;
