import React from 'react';
// styles
import './style.scss';
// Utilities
import classnames from 'classnames';
import Spinner from 'Assets/spinner.png';
var Button = function (props) {
    var _a;
    var className = classnames([
        props.className || '',
        "btn-" + (props.color || 'blue-1'),
        (_a = {
                'btn--full': props.fullWidth
            },
            _a["btn-" + props.color + "--small"] = !!props.size,
            _a),
    ]);
    return (React.createElement("button", { type: props.type, className: className, disabled: props.disabled, onClick: props.onClick }, props.loading ? React.createElement("img", { className: 'loading', src: Spinner }) : props.children));
};
export default Button;
