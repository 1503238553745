import React from 'react';
// Assets
import NewLogo from 'Assets/new_logo.svg';
import AppStoreImage from 'Assets/app_store_image.svg';
import GooglePlayImage from 'Assets/google_play_image.svg';
import DownloadAppImage from 'Assets/download_app_image.svg';
// Styles
import './style.scss';
var AppDownload = function () {
    return (React.createElement("div", { className: "animation fadeIn app-download-page" },
        React.createElement("img", { src: NewLogo, className: "app-download__edoctor-logo" }),
        React.createElement("div", { className: "app-download__slogan" }, "S\u1ED0NG KH\u1ECEE T\u1EA0I NH\u00C0"),
        React.createElement("div", { className: "app-download__subtitle" }, "N\u1EC1n t\u1EA3ng ch\u0103m s\u00F3c s\u1EE9c kh\u1ECFe cho ng\u01B0\u1EDDi Vi\u1EC7t"),
        React.createElement("img", { src: DownloadAppImage, className: "app-download__image" }),
        React.createElement("div", { className: "app-download__subtitle app-download__subtitle--text-bold" }, "S\u1EEC D\u1EE4NG APP"),
        React.createElement("div", { className: "app-download__recommend" }, "T\u1EA3i \u1EE9ng d\u1EE5ng \u0111\u1EC3 tr\u1EA3i nghi\u1EC7m t\u1ED1t h\u01A1n"),
        React.createElement("img", { src: AppStoreImage, className: "app-download__button-image", alt: "" }),
        React.createElement("img", { src: GooglePlayImage, className: "app-download__button-image", alt: "" })));
};
export default AppDownload;
