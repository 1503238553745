import React from 'react';
// Component
import { Link } from 'react-router-dom';
// Assets
import Error500 from 'Assets/error_500.png';
// Styles
import './style.scss';
var PageError = function (props) {
    return (React.createElement("div", { className: 'error-page page' },
        React.createElement("img", { className: 'page__img', src: Error500 }),
        React.createElement("h1", { className: 'page__title' }, "\u0110\u00E3 x\u1EA3y ra l\u1ED7i!"),
        React.createElement("div", { className: 'page__description' }, "L\u1ED7i m\u00E1y ch\u1EE7 500. Nh\u00E2n vi\u00EAn c\u1EE7a ch\u00FAng t\u00F4i \u0111\u00E3 \u0111\u01B0\u1EE3c th\u00F4ng b\u00E1o, c\u1EA3m \u01A1n b\u1EA1n \u0111\u00E3 hi\u1EC3u."),
        React.createElement(Link, { to: '/', className: 'btn-blue-1' }, "V\u1EC0 TRANG CH\u1EE6")));
};
export default PageError;
