var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Suspense } from 'react';
// Components
import Loading from 'Components/Loading';
import Account from 'Lib/Contexts/Http/Account';
import InitData from 'Lib/Contexts/Http/InitData';
import UploadWrapper from 'Lib/Contexts/Http/Upload';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// Middlewares
import ErrorBoundaries from 'Lib/Middlewares/ErrorBoundaries';
import DeviceDetectMiddleware from 'Lib/Middlewares/DeviceDetect';
// Pages
var Login = React.lazy(function () { return import('Pages/Login'); });
var Main = React.lazy(function () { return import('Components/Router'); });
var Verifying = React.lazy(function () { return import('Pages/Verifying'); });
// Styles
import 'Styles/App.scss';
// Contexts
import * as HttpContext from 'Lib/Contexts/Http';
import * as TokenContext from 'Lib/Contexts/Token';
import * as ThemeContext from 'Lib/Contexts/Theme';
import * as LoadingContext from 'Lib/Contexts/Loading';
// Utils
import { combineContextProviders } from 'Lib/Utils';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.render = function () {
            return (React.createElement(Router, null,
                React.createElement(DeviceDetectMiddleware, null,
                    React.createElement(ErrorBoundaries, null,
                        React.createElement(InitData, null,
                            React.createElement(Account, null,
                                React.createElement(UploadWrapper, null,
                                    React.createElement("div", { className: "app", id: "app" },
                                        React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                                            React.createElement(Switch, null,
                                                React.createElement(Route, { path: '/dang-nhap', component: Login, exact: true }),
                                                React.createElement(Route, { exact: true, path: '/cho-xac-thuc', component: Verifying }),
                                                React.createElement(Route, { path: '/', component: Main }),
                                                React.createElement(Redirect, { to: '/' })))))))))));
        };
        return _this;
    }
    return App;
}(Component));
export default combineContextProviders([
    LoadingContext,
    ThemeContext,
    TokenContext,
    HttpContext,
])(App);
