import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

// Components
import App from 'Components/App';

const isProduction = process.env.ENV === 'production';
if (isProduction && process.env.SENTRY_DSN) {
    Sentry.init({ dsn: process.env.SENTRY_DSN });
}

ReactDOM.render(<App />, document.getElementById('root'));
