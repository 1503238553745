var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Cookies from 'js-cookie';
var initState = {
    value: null,
    get: function () { return null; },
    set: function (token) { return token; },
    remove: function () { return null; },
};
export var Context = React.createContext(initState);
export var useTokenContext = function () { return React.useContext(Context); };
export var useContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.render = function () {
                return (React.createElement(Context.Consumer, null, function (_a) {
                    var value = _a.value, get = _a.get, set = _a.set, remove = _a.remove;
                    return (React.createElement(WrappedComponent, __assign({}, _this.props, { token: { value: value, get: get, set: set, remove: remove } })));
                }));
            };
            return _this;
        }
        return class_1;
    }(Component));
};
export var wrapContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_2, _super);
        function class_2(props) {
            var _this = _super.call(this, props) || this;
            _this.set = function (value) {
                _this.setState({ value: value }, function () {
                    localStorage.setItem('token', value);
                    Cookies.set('token', value, {
                        expires: 356,
                    });
                    Cookies.set('upload_token', value, {
                        expires: 356,
                    });
                });
            };
            _this.remove = function () {
                _this.setState({ value: null }, function () {
                    Cookies.remove('token');
                    Cookies.remove('upload_token');
                    localStorage.removeItem('token');
                });
            };
            _this.get = function () { return _this.state.value; };
            _this.render = function () {
                var token = {
                    value: _this.state.value,
                    remove: _this.remove,
                    set: _this.set,
                    get: _this.get,
                };
                return (React.createElement(Context.Provider, { value: token },
                    React.createElement(WrappedComponent, __assign({}, _this.props))));
            };
            _this.state = {
                value: localStorage.getItem('token'),
            };
            return _this;
        }
        return class_2;
    }(Component));
};
