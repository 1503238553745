var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
// Assets
import Logo from 'Assets/logo.png';
// Utils
import classnames from "classnames";
// Styles
import "./style.scss";
;
var initState = {
    state: false,
    set: function () { return null; },
    resourceLoading: function (loadingRes) { return null; },
};
export var Context = React.createContext(initState);
export var useLoadingContext = function () { return React.useContext(Context); };
;
;
export var wrapContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(Wrapper, _super);
        function Wrapper(props) {
            var _this = _super.call(this, props) || this;
            _this.shouldComponentUpdate = function (nextProps, nextState) {
                var isLoadingChanged = nextState.loading !== _this.state.loading;
                var isLoadingResChanged = nextState.loadingRes !== _this.state.loadingRes;
                var isLoadingResCompleteChanged = nextState.loadingResCompleted !== _this.state.loadingResCompleted;
                return isLoadingChanged || isLoadingResChanged || isLoadingResCompleteChanged;
            };
            _this.componentDidUpdate = function (prevProps, prevState) {
            };
            _this.setTimeoutLoading = null;
            _this.set = function (loading) {
                if (loading !== _this.state.loading) {
                    clearTimeout(_this.setTimeoutLoading);
                    if (loading === false) {
                        _this.setTimeoutLoading = setTimeout(function () {
                            _this.setState({ loading: loading });
                        }, 200);
                    }
                    else {
                        _this.setState({ loading: true });
                    }
                }
            };
            _this.setTimeoutLoadingRes = null;
            _this.resourceLoading = function (loadingRes) {
                if (loadingRes === void 0) { loadingRes = false; }
                if (loadingRes !== _this.state.loadingRes) {
                    clearTimeout(_this.setTimeoutLoadingRes);
                    if (loadingRes === false) {
                        _this.setState({ loadingResCompleted: true }, function () {
                            _this.setTimeoutLoadingRes = setTimeout(function () {
                                _this.setState({ loadingRes: loadingRes });
                            }, 300);
                        });
                    }
                    else {
                        _this.setState({ loadingRes: loadingRes, loadingResCompleted: false });
                    }
                }
            };
            _this.render = function () {
                var value = {
                    resourceLoading: _this.resourceLoading,
                    state: _this.state.loading,
                    set: _this.set
                };
                var globalLoadingClassName = classnames([
                    "global-loading animated fadeIn", {
                        "global-loading_loading": _this.state.loading
                    }
                ]);
                var resourceLoadingClassName = classnames([
                    "resource-loading", {
                        "resource-loading_completed": _this.state.loadingResCompleted
                    }
                ]);
                return (React.createElement(Context.Provider, { value: value },
                    React.createElement(WrappedComponent, __assign({}, _this.props)),
                    _this.state.loadingRes ? React.createElement("div", { className: resourceLoadingClassName }) : null,
                    React.createElement("div", { className: globalLoadingClassName },
                        React.createElement("div", { className: 'global-loading__content' },
                            React.createElement("img", { src: Logo, className: 'global-loading__logo' }),
                            React.createElement("h3", { className: 'global-loading__title' }, "Vui l\u00F2ng \u0111\u1EE3i trong gi\u00E2y l\u00E1t..."),
                            React.createElement("div", { className: "global-loading__progressing" },
                                React.createElement("span", { className: "global-loading__progressing-bar" }))))));
            };
            _this.state = {
                // Loading API
                loading: false,
                // Loading resources [split code purpose]
                loadingRes: false,
                loadingResCompleted: false
            };
            return _this;
        }
        return Wrapper;
    }(Component));
};
// Remove later
export var useContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.render = function () {
                return (React.createElement(Context.Consumer, null, function (_a) {
                    var state = _a.state, set = _a.set, resourceLoading = _a.resourceLoading;
                    return React.createElement(WrappedComponent, __assign({}, _this.props, { loading: { state: state, set: set, resourceLoading: resourceLoading } }));
                }));
            };
            return _this;
        }
        return class_1;
    }(Component));
};
