var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
// Contexts
import * as TokenContext from 'Lib/Contexts/Token';
import * as AlertContext from 'Lib/Contexts/Alert';
// Utils
import axios from 'axios';
import * as Utils from 'Lib/Utils';
import QueryString from 'querystring';
var initState = {
    get: function () { return null; },
    put: function () { return null; },
    post: function () { return null; },
    instance: function () { return null; },
    setIgnoreError: function () { return null; },
};
export var Context = React.createContext(initState);
export var useHttpContext = function () { return React.useContext(Context); };
var createHttp = function (token) {
    return axios.create({
        baseURL: process.env.API_URL,
        headers: {
            Authorization: "Bearer " + token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
};
export var wrapContext = function (WrappedComponent) {
    var Wrapper = /** @class */ (function (_super) {
        __extends(Wrapper, _super);
        function Wrapper(props) {
            var _this = _super.call(this, props) || this;
            _this.ignoreError = false;
            _this.componentDidMount = function () {
                _this.http = createHttp(_this.props.token.value);
            };
            _this.shouldComponentUpdate = function (nextProps) {
                var isTokenChanged = _this.props.token.value !== nextProps.token.value;
                return isTokenChanged;
            };
            _this.componentDidUpdate = function (prevProps) {
                if (prevProps.token.value !== _this.props.token.value) {
                    _this.http = createHttp(_this.props.token.value);
                }
            };
            _this.get = function (url, params, options) {
                if (params === void 0) { params = {}; }
                if (options === void 0) { options = {}; }
                return __awaiter(_this, void 0, void 0, function () {
                    var response, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.http.get(url, __assign({ params: params, paramsSerializer: function (params) {
                                            return QueryString.stringify(params);
                                        } }, options))];
                            case 1:
                                response = _a.sent();
                                return [2 /*return*/, response.data];
                            case 2:
                                error_1 = _a.sent();
                                this.handleResponseError(error_1);
                                throw error_1;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            _this.post = function (url, data, params) {
                if (params === void 0) { params = {}; }
                return __awaiter(_this, void 0, void 0, function () {
                    var response, error_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.http.post(url, data, {
                                        params: params,
                                        paramsSerializer: function (params) {
                                            return QueryString.stringify(params);
                                        },
                                    })];
                            case 1:
                                response = _a.sent();
                                return [2 /*return*/, response.data];
                            case 2:
                                error_2 = _a.sent();
                                this.handleResponseError(error_2);
                                throw error_2;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            _this.put = function (url, data, params) {
                if (params === void 0) { params = {}; }
                return __awaiter(_this, void 0, void 0, function () {
                    var response, error_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.http.put(url, data, {
                                        params: params,
                                        paramsSerializer: function (params) {
                                            return QueryString.stringify(params);
                                        },
                                    })];
                            case 1:
                                response = _a.sent();
                                return [2 /*return*/, response.data];
                            case 2:
                                error_3 = _a.sent();
                                this.handleResponseError(error_3);
                                throw error_3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            _this.instance = function () { return _this.http; };
            // set ignore === true for ignoring alert when response error.
            _this.setIgnoreError = function (bool) {
                _this.ignoreError = bool;
            };
            // Handlers
            _this.handleResponseError = function (error) {
                if (_this.ignoreError) {
                    _this.ignoreError = false;
                    return null;
                }
                if (error.response && error.response.data) {
                    var data = error.response.data;
                    var errorKey = data.errorKey || '';
                    if (errorKey === 'USER_TOKEN_INVALID') {
                        _this.props.token.remove();
                    }
                    if (errorKey === 'VALIDATION_ERROR') {
                        return _this.props.alert.error('Dữ liệu cung cấp không hợp lệ!');
                    }
                    _this.props.alert.error(data.userMessage);
                }
                else {
                    _this.props.alert.error();
                }
            };
            // Renders
            _this.render = function () {
                var value = {
                    get: _this.get,
                    put: _this.put,
                    post: _this.post,
                    instance: _this.instance,
                    setIgnoreError: _this.setIgnoreError,
                };
                return (React.createElement(Context.Provider, { value: value },
                    React.createElement(WrappedComponent, __assign({}, _this.props))));
            };
            _this.state = {
                token: props.token.value,
            };
            _this.ignoreError = false;
            _this.http = createHttp(props.token.value);
            return _this;
        }
        return Wrapper;
    }(Component));
    return Utils.useContextAsProps({
        token: TokenContext.Context,
        alert: AlertContext.Context,
    })(Wrapper);
};
export var useContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.render = function () {
                return (React.createElement(Context.Consumer, null, function (_a) {
                    var get = _a.get, put = _a.put, post = _a.post, instance = _a.instance, setIgnoreError = _a.setIgnoreError;
                    var http = { get: get, put: put, post: post, instance: instance, setIgnoreError: setIgnoreError };
                    return React.createElement(WrappedComponent, __assign({}, _this.props, { http: http }));
                }));
            };
            return _this;
        }
        return class_1;
    }(Component));
};
