import React from 'react';
// Utilities
import classnames from 'classnames';
// Styles
import './style.scss';
// Component
import Button from 'Components/Button';
import { Modal, ModalHeader } from 'reactstrap';
// Assets
import ErrorIcon from 'Assets/error_icon.png';
import SuccessIcon from 'Assets/success_icon.png';
import WarningIcon from 'Assets/warning_alert_icon.png';
var Alert = function (props) {
    var _a;
    var className = classnames([
        'alert',
        props.className || '',
        (_a = {},
            _a["alert--" + props.type] = props.type,
            _a['alert--small'] = props.size === 'small',
            _a),
    ]);
    var Icon = props.icon;
    if (props.type === 'success') {
        Icon = SuccessIcon;
    }
    else if (props.type === 'warning') {
        Icon = WarningIcon;
    }
    else if (props.type === 'error') {
        Icon = ErrorIcon;
    }
    return (React.createElement(Modal, { centered: true, className: className, isOpen: props.isOpen },
        React.createElement(ModalHeader, { className: 'modal-alert__header' }, props.type && (React.createElement(React.Fragment, null,
            Icon && (React.createElement("div", { className: 'modal-alert__icon' },
                React.createElement("img", { src: Icon }))),
            React.createElement("span", { className: 'modal-alert__title' }, props.title)))),
        props.message && React.createElement("div", { className: 'modal-alert__body' }, props.message),
        React.createElement("div", { className: 'modal__footer' },
            React.createElement(Button, { onClick: props.onClose, size: props.btnCloseSize, color: props.btnCloseColor, className: props.btnCloseClassName }, props.btnCloseLabel || 'Đóng'),
            props.btnAcceptLabel && (React.createElement(Button, { onClick: props.onAccept, size: props.btnAcceptSize, color: props.btnAcceptColor, className: props.btnAcceptClassName }, props.btnAcceptLabel)))));
};
Alert.defaultProps = {
    btnCloseColor: 'gray-1',
};
export default Alert;
