var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
var initState = {
    isMinimize: false,
    toggleMinimize: function () { return null; },
};
export var Context = React.createContext(initState);
export var useThemeContext = function () { return React.useContext(Context); };
export var useContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.render = function () {
                return (React.createElement(Context.Consumer, null, function (_a) {
                    var isMinimize = _a.isMinimize, toggleMinimize = _a.toggleMinimize;
                    return (React.createElement(WrappedComponent, __assign({}, _this.props, { theme: { isMinimize: isMinimize, toggleMinimize: toggleMinimize } })));
                }));
            };
            return _this;
        }
        return class_1;
    }(Component));
};
export var wrapContext = function (WrappedComponent) {
    var Wrapper = /** @class */ (function (_super) {
        __extends(Wrapper, _super);
        function Wrapper(props) {
            var _this = _super.call(this, props) || this;
            // Handlers
            _this.handleToggle = function (isMinimize) { return _this.setState({ isMinimize: isMinimize }, function () {
                localStorage.setItem('minimized', isMinimize ? "true" : "false");
            }); };
            // Renders
            _this.render = function () {
                var value = {
                    isMinimize: _this.state.isMinimize,
                    toggleMinimize: _this.handleToggle,
                };
                return (React.createElement(Context.Provider, { value: value },
                    React.createElement(WrappedComponent, __assign({}, _this.props))));
            };
            _this.state = {
                isMinimize: localStorage.getItem('minimized') == "true",
            };
            return _this;
        }
        return Wrapper;
    }(Component));
    return Wrapper;
};
