import React from 'react';
import { isMobile } from 'react-device-detect';

// Components
import AppDownload from 'Pages/AppDownload';

const DeviceDetectMiddleware = props => {
    if (isMobile) {
        return <AppDownload />
    }

    return props.children;
}

export default DeviceDetectMiddleware;
