var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
// Components
import Alert from 'Components/Alert';
// Style
import './style.scss';
var initState = {
    error: function () { return null; },
    success: function () { return null; },
    warning: function () { return null; },
    confirm: function () { return null; },
};
export var Context = React.createContext(initState);
export var useAlertContext = function () { return React.useContext(Context); };
var DEFAULT_ERROR_MSG = 'Đã có lỗi xảy ra! Vui lòng thử lại hoặc liên hệ đến đội ngũ phát triển.';
export var useContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.render = function () {
                return (React.createElement(Context.Consumer, null, function (_a) {
                    var error = _a.error, warning = _a.warning, success = _a.success, confirm = _a.confirm;
                    return (React.createElement(WrappedComponent, __assign({}, _this.props, { alert: { error: error, warning: warning, success: success, confirm: confirm } })));
                }));
            };
            return _this;
        }
        return class_1;
    }(Component));
};
export var wrapContext = function (WrappedComponent) {
    return /** @class */ (function (_super) {
        __extends(class_2, _super);
        function class_2(props) {
            var _this = _super.call(this, props) || this;
            _this.toggle = function () { return _this.setState({ show: !_this.state.show }); };
            _this.show = function () { return _this.setState({ show: true }); };
            _this.hide = function () { return _this.setState({ show: false }); };
            _this.error = function (message, title) {
                if (message === void 0) { message = DEFAULT_ERROR_MSG; }
                if (title === void 0) { title = 'Thất bại'; }
                return _this.setState({
                    title: title,
                    message: message,
                    show: true,
                    type: 'error',
                });
            };
            _this.warning = function (message, title, btnLabel) {
                if (title === void 0) { title = 'Cảnh bảo'; }
                if (btnLabel === void 0) { btnLabel = 'Xác nhận'; }
                return _this.setState({
                    title: title,
                    message: message,
                    show: true,
                    type: 'warning',
                    btnLabel: btnLabel,
                });
            };
            _this.success = function (message, title) {
                if (title === void 0) { title = 'Thành công'; }
                return _this.setState({
                    title: title,
                    message: message,
                    show: true,
                    type: 'success',
                });
            };
            _this.confirm = function (message, title, btnLable, onClick) {
                if (title === void 0) { title = 'Xác nhận'; }
                if (btnLable === void 0) { btnLable = 'Xác nhận'; }
                if (onClick === void 0) { onClick = function () { return null; }; }
                return _this.setState({
                    title: title,
                    message: message,
                    onClick: onClick,
                    show: true,
                    btnLabel: btnLable,
                    type: 'confirm',
                });
            };
            // Handlers
            _this.handleOnClick = function () {
                _this.setState({ show: false }, function () {
                    _this.state.onClick();
                });
            };
            // Renders
            _this.render = function () {
                var value = {
                    error: _this.error,
                    warning: _this.warning,
                    success: _this.success,
                    confirm: _this.confirm,
                };
                return (React.createElement(Context.Provider, { value: value },
                    React.createElement(WrappedComponent, __assign({}, _this.props)),
                    React.createElement(Alert, { onClose: _this.toggle, type: _this.state.type, isOpen: _this.state.show, title: _this.state.title, message: _this.state.message, onAccept: _this.handleOnClick, btnAcceptLabel: _this.state.btnLabel })));
            };
            _this.state = {
                type: null,
                show: false,
                title: null,
                message: null,
                btnLabel: null,
                onClick: function () { return null; },
            };
            return _this;
        }
        class_2.prototype.componentDidUpdate = function (prevProps, prevState) {
            if (!this.state.show && this.state.show !== prevState.show) {
                return this.setState({
                    type: null,
                    show: false,
                    title: null,
                    message: null,
                    btnLabel: null,
                    onClick: function () { return null; },
                });
            }
        };
        return class_2;
    }(Component));
};
