var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable no-console */
import React from 'react';
import moment from 'moment';
import Dinero from 'dinero.js';
import lodashGet from 'lodash/get';
// REMOVE later
export var combineContextProviders = function (contexts) { return function (WrappedComponent) {
    var ConnectedComponent = WrappedComponent;
    contexts.reverse().forEach(function (context) {
        if (context.wrapContext) {
            ConnectedComponent = context.wrapContext(ConnectedComponent);
        }
        else {
            throw new Error("Context object doesn't have wrapContext function");
        }
    });
    return ConnectedComponent;
}; };
// REMOVE later
export var combineContextConsumers = function (contexts) { return function (WrappedComponent) {
    var ConnectedComponent = WrappedComponent;
    contexts.forEach(function (context) {
        if (context.useContext) {
            ConnectedComponent = context.useContext(ConnectedComponent);
        }
        else {
            throw new Error("Context object doesn't have useContext function");
        }
    });
    return ConnectedComponent;
}; };
export var combineContexts = function (contexts) { return function (WrappedComponent) {
    var WrappedConsumer = combineContextConsumers(contexts)(WrappedComponent);
    return combineContextProviders(contexts)(WrappedConsumer);
}; };
export var getAge = function (year, defaults) {
    var age = new Date().getFullYear() - year;
    return age > 0 ? age : defaults;
};
export var getGender = function (gender) {
    return gender === 'male' ? 'Nam' : 'Nữ';
};
export var getAttachmentImage = function (attachmentId, version) {
    if (version === void 0) { version = 320; }
    return process.env.UPLOAD_URL + "/v1/attachment/image/" + attachmentId + "/" + version;
};
export var getProxyImage = function (attachmentId, width) {
    return process.env.UPLOAD_PROXY_URL + "_upload/image/" + attachmentId + (width ? "?width=" + width : '');
};
export var getAttachmentVoice = function (attachmentId) {
    return process.env.UPLOAD_URL + "/v1/attachment/voice/" + attachmentId;
};
export var formatCurrency = function (amount, format) {
    if (format === void 0) { format = '$0,0'; }
    var price = amount || 0;
    return Dinero({ amount: price, currency: 'VND', precision: 0 })
        .setLocale('vi-VN')
        .toFormat(format);
};
export var getAttachmentAvatar = function (userId, userType, version, type) {
    if (version === void 0) { version = 320; }
    if (type === void 0) { type = 'user_avatar'; }
    return process.env.UPLOAD_URL + "/v1/" + type + "/" + userType + "/" + userId + "/" + version;
};
export var getConsultationAvatar = function (userId, version, type) {
    if (version === void 0) { version = 320; }
    return process.env.UPLOAD_URL + "/v1/" + type + "/" + userId + "/" + version;
};
export var convertServerMonth = function (month) {
    return month + 1;
};
export var formatTime = function (duration) {
    var s = 1000;
    var m = s * 60;
    var h = m * 60;
    var d = h * 24;
    var hour = Math.floor((duration % d) / h);
    var minute = Math.floor((duration % h) / m);
    var second = Math.floor((duration % m) / s);
    return { hour: hour, minute: minute, second: second };
};
export var formatNumber = function (number, currency, separator) {
    if (separator === void 0) { separator = '.'; }
    var str = number.toLocaleString(currency);
    str = str.replace(/,/g, separator);
    return str;
};
export var useContextAsProps = function (contextObj) { return function (WrappedComponent) {
    return function (props) {
        var contextProps = {};
        for (var key in contextObj) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            contextProps[key] = React.useContext(contextObj[key]);
        }
        return React.createElement(WrappedComponent, __assign({}, props, contextProps));
    };
}; };
// Utils
export var getAccountAvatar = function (consumerId) {
    return !consumerId
        ? null
        : getConsultationAvatar(consumerId, 'original', 'consumer_profile_avatar');
};
export var getBirthDateAge = function (birthDateString) {
    var today = new Date();
    var birthDate = moment(birthDateString, 'YYYY-MM-DD');
    var yearsDifference = today.getFullYear() - birthDate.year();
    if (today.getMonth() < birthDate.month() && today.getDate() > birthDate.date()) {
        return yearsDifference - 1 < 0 ? 0 : yearsDifference - 1;
    }
    return yearsDifference;
};
export var appointmentList = function (httpContext, options) { return __awaiter(void 0, void 0, void 0, function () {
    var response, serviceBookings, appointment;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, httpContext.get('/v1/appointment/list', options)];
            case 1:
                response = _b.sent();
                serviceBookings = response.serviceBookings || [];
                appointment = {};
                serviceBookings.forEach(function (item) {
                    var _a;
                    var date = moment((_a = item === null || item === void 0 ? void 0 : item.telemedicine) === null || _a === void 0 ? void 0 : _a.estimateStartTime).format('DD-MM-YYYY');
                    if (appointment[date]) {
                        appointment[date].push(item);
                    }
                    else {
                        appointment[date] = [item];
                    }
                });
                return [2 /*return*/, { FilterRecord: (_a = response.pagination) === null || _a === void 0 ? void 0 : _a.filteredRecords, AppointmentResult: appointment }];
        }
    });
}); };
export var LodashGet = function (object, path, defaultValue) {
    return lodashGet(object, path, defaultValue);
};
export var Logger = (function () {
    var logger = {
        log: function () { return null; },
        info: function () { return null; },
        warn: function () { return null; },
        error: function () { return null; },
    };
    if (process.env.ENV === 'development') {
        logger.log = function (data) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            return console.log.apply(console, __spreadArrays(['[LOGGER]', data], args));
        };
        logger.info = function (data) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            return console.log.apply(console, __spreadArrays(['[LOGGER][INFO]', data], args));
        };
        logger.warn = function (data) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            return console.warn.apply(console, __spreadArrays(['[LOGGER][WARN]', data], args));
        };
        logger.error = function (data) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            return console.error.apply(console, __spreadArrays(['[LOGGER][ERROR]', data], args));
        };
    }
    return logger;
})();
